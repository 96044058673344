import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from 'components/Layout'
import Post from 'components/Post'

import IconPrev from 'assets/icons/anchor-left.svg'
import IconNext from 'assets/icons/anchor-right.svg'

function generatePages (totalPages, page) {
  let items = []

  for (let i = 1; i <= totalPages; i++) {
    if (i === page) {
      items.push(
        <span aria-current="page" className="page-numbers current">{page}</span>
      )
    } else {
      items.push(
        <Link to={i === 1 ? `/` : `/page/${i}/`} aria-label={`Page ${i}`} className="page-numbers">{i}</Link>
      )
    }
  }

  return items
}

const Pagination = ({ page, totalPages, hasNext, hasPrev }) => (
  <nav className="navigation pagination" role="navigation">
		<h2 className="screen-reader-text">Posts navigation</h2>
		<div className="nav-links">
      {hasPrev &&
        <Link className="prev page-numbers" to={(page - 1) === 1 ? `/` : `/page/${page - 1}/`} aria-label={`Newer posts`}>
          <IconPrev />
          <span className="nav-prev-text">Newer posts</span>
        </Link>
      }
      {generatePages(totalPages, page)}
      {hasNext &&
        <Link className="next page-numbers" to={(page + 1) === 1 ? `/` : `/page/${page + 1}/`} aria-label={`Older posts`}>
          <span className="nav-next-text">Older posts</span>
          <IconNext />
        </Link>
      }
    </div>
	</nav>
)

export default (props) => {
  let { location, data: { wordpress }, pageContext } = props

  return (
    <Layout location={location}>
      {wordpress.posts && wordpress.posts.edges.map(post => (
        <Post
          key={post.node.id}
          post={post}
          isSticky={pageContext.page === 1 && wordpress.stickyPosts.edges.filter(({ node }) => (node.id === post.node.id)).length > 0}
        />
      ))}
      <Pagination {...pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($page: Int!) {
    wordpress {
      stickyPosts: posts(where: { onlySticky: true, status: PUBLISH }) {
        edges {
          node {
            id
          }
        }
      }
      posts(where: { status: PUBLISH, paged: $page }) {
        edges {
          node {
            id
            postId
            slug
            title
            excerpt
            date
            dateGmt
            modified
            modifiedGmt
            commentCount
            author {
              name
              slug
            }
            tags {
              edges {
                node {
                  slug
                  name
                }
              }
            }
            categories {
              edges {
                node {
                  slug
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
