import React from 'react';

import EntryHeader from './EntryHeader';
import EntryContent from './EntryContent';
import EntryFooter from './EntryFooter';

export default ({ post, isSticky }) => (
  <article
    id={`post-${post.node.postId}`}
    className={`post-${post.node.postId} post type-post status-publish format-standard hentry category-uncategorized entry`}
  >
    <EntryHeader post={post.node} isSticky={isSticky} />
    <EntryContent post={post.node} />
    <EntryFooter post={post.node} />
  </article>
)
