import React from "react"
import { Link } from "gatsby"

import IconPostedBy from 'assets/icons/posted-by.svg'
import IconPostedOn from 'assets/icons/posted-on.svg'
import IconCatLinks from 'assets/icons/cat-links.svg'
import IconComments from 'assets/icons/comments.svg'
import IconTags from 'assets/icons/tags.svg'

const formatDate = (date) => (new Date(date)).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })

export default ({ post, isSingle = false }) => (
  <footer className="entry-footer">
    <span className="byline">
      <IconPostedBy />
      <span className="screen-reader-text">Posted by</span>
      <span className="author vcard">
        <Link className="url fn n" to={`/author/${post.author.slug}/`}>{post.author.name}</Link>
      </span>
    </span>
    <span className="posted-on">
      <IconPostedOn />
      <Link to={`/${post.slug}/`} rel="bookmark">
        <time className="entry-date published" dateTime={post.dateGmt}>{formatDate(post.date)}</time>
        <time className="updated" dateTime={post.modifiedGmt}>{formatDate(post.modified)}</time>
      </Link>
    </span>
    {post.categories && post.categories.edges.length > 0 &&
      <span className="cat-links">
        <IconCatLinks />
        <span className="screen-reader-text">Posted in</span>
        {post.categories.edges.map(({ node }) => (
          <Link to={`/category/${node.slug}/`} rel="category tag">
            {node.name}
          </Link>
        ))}
      </span>
    }
    {post.tags && post.tags.edges.length > 0 &&
      <span className="tags-links">
        <IconTags />
        <span className="screen-reader-text">Tags: </span>
        {post.tags.edges.map(({ node }, i) => (
          <React.Fragment>
            <Link to={`/tag/${node.slug}/`}>{node.name}</Link>
            {i + 1 !== post.tags.edges.length ? `, ` : null}
          </React.Fragment>
        ))}
      </span>
    }
    {!isSingle &&
      <span className="comments-link">
        <IconComments />
        <Link to={`/${post.slug}/#comments`}>
          {post.commentCount === 1 ? '1 Comment' : `${post.commentCount === null ? 'Leave a comment' : `${post.commentCount} Comments`}`}
          <span className="screen-reader-text"> on {post.title}</span>
        </Link>
      </span>
    }
  </footer>
)
