import React from 'react'
import { Link } from 'gatsby'

export default ({ post, isSticky }) => (
  <header className="entry-header">
    {isSticky &&
      <span className="sticky-post">Featured</span>
    }
    <h2 className="entry-title">
      <Link to={`/${post.slug}/`}>{post.title}</Link>
    </h2>
  </header>
)
